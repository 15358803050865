<template>
  <div>
    <form-proyecto :proyectoId="$route.params.id"></form-proyecto>
  </div>
</template>

<script>
import formProyecto from "@/components/proyectos/formProyecto.vue";
export default {
  components: {
    formProyecto,
  },
};
</script>

<style></style>
